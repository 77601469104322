<template>
  <div class="app-context util-page" style="min-width: 320px">
    <!--<app-header :headerOption="headerOption" ref="appheader"></app-header>-->
    <div class="r-notice">
      <div class="notice">
        <p style="min-width: 332px">
          <span v-if="pr.hospNm.length < 9"> 
            <b>{{ pr.hospNm }}</b
            >의 소개페이지입니다.
          </span>
          <span v-else style="font-size : 16px;">
            <b>{{ pr.hospNm }}</b
            >의 소개페이지입니다.
          </span>
        </p>
      </div>
    </div>
    <div class="pr-wrap default onView" v-if="view.pageOrder === 0">
      <div class="ab-btb-wrap" v-if="loadBtn === 0">
        <div class="prev-btn a-btn-item" @click="closeTab()">
          <div class="img-wrap">
            <img class="close" src="@/assets/images/menuIcon/cancel.svg" />
          </div>
          <p>닫기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 1">
        <div class="prev-btn a-btn-item" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/prev-btn.svg" />
          </div>
          <p>뒤로가기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 2">
        <div class="a-btn-item home-btn" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/btn_home.png" />
          </div>
          <p>홈페이지</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="pr-context">
        <div class="front">
          <div class="container">
            <div class="title hospital-logo row">
              <div
                class="put-logo noneHover col-md-4 col-sm-12"
                v-if="
                  GET_PROMOTION.logoAttchId === '' ||
                    GET_PROMOTION.logoAttchId === null
                "
              ></div>
              <div class="img-wrap col-md-4 col-sm-12" v-else>
                <img :src="pr.logoAttchId" style="min-width: 256px" />
              </div>

              <h2 class="col-md-5" style="min-width: 320px">{{ pr.hospNm }}</h2>
            </div>
            <div class="sub-title">
              <h3 v-html="computedLine(pr.hospIntro)"></h3>
            </div>
            <div class="vision">
              <p v-html="computedLine(pr.conConts)"></p>
            </div>
          </div>
        </div>
        <div class="back">
          <div class="container-wrap">
            <div class="container">
              <div class="profile row">
                <div
                  class="
                    put-profile
                    noneHover
                    col-lg-3 col-md-12 col-sm-12 col-12
                  "
                  v-if="
                    GET_PROMOTION.drPicAttchId === '' ||
                      GET_PROMOTION.drPicAttchId === null
                  "
                ></div>

                <div
                  class="img-wrap col-lg-3 col-md-12 col-sm-12 col-12"
                  v-else
                >
                  <img :src="pr.drPicAttchId" style="min-width: 209px" />
                </div>
                <div class="p-context col-lg-8 col-md-12 col-sm-12 col-12">
                  <div class="header">
                    <h4 class="title">
                      안녕하세요?<br />{{ pr.hospNm }} {{ pr.drNm }}입니다.
                    </h4>
                    <h6 class="history" style="min-width: 250px">
                      {{ pr.drHist }}
                    </h6>
                  </div>
                  <div class="content">
                    <p
                      style="min-width: 250px"
                      v-html="computedLine(pr.doctIntro)"
                    ></p>
                  </div>
                </div>
              </div>
              <div class="hospital">
                <h4 class="title">{{ pr.hospNm }}</h4>
                <div class="info row">
                  <div
                    class="article-wrap wide col-lg-7"
                    style="min-width: 320px"
                  >
                    <div class="article">
                      <h5 class="title">주소</h5>
                      <p>{{ pr.addr }} {{ pr.addrDtl }}</p>
                    </div>
                    <div class="article">
                      <h5 class="title">전화,팩스</h5>
                      <p
                        v-if="
                          (pr.hospTel !== null) &
                            (pr.hospFaxno !== null) &
                            (pr.hospTel !== '') &
                            (pr.hospFaxno !== '')
                        "
                      >
                        전화: {{ pr.hospTel }} 팩스: {{ pr.hospFaxNo }}
                      </p>
                      <p
                        v-if="
                          (pr.hospTel !== null) &
                            (pr.hospFaxno === null) &
                            (pr.hospTel !== '') &
                            (pr.hospFaxno === '')
                        "
                      >
                        전화: {{ pr.hospTel }}
                      </p>
                    </div>
                    <div class="article sample-view">
                      <h5 class="title">웹사이트</h5>
                      <div class="input-grid">
                        <p
                          class="type-name"
                          v-if="pr.homepage !== null && pr.homepage !== ''"
                        >
                          홈페이지
                          <a :href="'http://' + pr.homepage">{{
                            pr.homepage
                          }}</a>
                        </p>
                        <p
                          class="type-name"
                          v-if="pr.rsvUrl !== null && pr.rsvUrl !== ''"
                        >
                          예약페이지
                          <a :href="'http://' + pr.rsvUrl">{{ pr.rsvUrl }}</a>
                        </p>
                        <p
                          class="type-name"
                          v-for="(etcP, index) in pr.etcPage"
                          :key="index + 'e'"
                        >
                          {{ etcP.type }}
                          <a
                            :href="'http://' + etcP.value"
                            v-if="pr.etcPage[index].value"
                            >{{ etcP.value }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="article-wrap default">
                    <div class="article">
                      <h5 class="title">진료시간</h5>
                      <p v-html="computedLine(pr.etcConts)"></p>
                    </div>
                  </div>
                  <div class="article-wrap whole">
                    <div class="article">
                      <h5 class="title">오시는길</h5>
                      <div
                        class="put-whole-img noneHover"
                        v-if="
                          GET_PROMOTION.mapAttchId === '' ||
                            GET_PROMOTION.mapAttchId === null
                        "
                      ></div>

                      <div class="map-wrap" v-else>
                        <img :src="pr.mapAttchId" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="editor-box container" v-if="this.pr.docAddvTitle !== null">
        <div class="e-article">
          <h3 class="title" v-text="this.pr.docAddvTitle"></h3>
        </div>
        <div class="e-article">
          <div class="editor-wrap">
            <p class="content" v-html="this.pr.docAddvConts"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="pr-wrap special onView" v-if="view.pageOrder === 1">
      <div class="ab-btb-wrap" v-if="loadBtn === 0">
        <div class="prev-btn a-btn-item" @click="closeTab()">
          <div class="img-wrap">
            <img class="close" src="@/assets/images/menuIcon/cancel.svg" />
          </div>
          <p>닫기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 1">
        <div class="prev-btn a-btn-item" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/prev-btn.svg" />
          </div>
          <p>뒤로가기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 2">
        <div class="a-btn-item home-btn" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/btn_home.png" />
          </div>
          <p>홈페이지</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="pr-item">
        <div class="pr-block align-center first row">
          <div class="pre-content1 col-lg-3 col-md-12 col-sm-12 col-12">
            <div
              class="logo-wrap hospital-logo"
              v-if="
                GET_PROMOTION.logoAttchId === '' ||
                  GET_PROMOTION.logoAttchId === null
              "
            >
              <div class="put-logo noneHover">
                <!--
              <img src="@/assets/images/attach_circle.svg" />
              <p class="explain">Logo</p>
              --></div>
            </div>
            <div class="logo-wrap hospital-logo" v-else>
              <div class="img-wrap view-page">
                <img :src="pr.logoAttchId" />
              </div>
            </div>
          </div>
          <div
            class="block-content doctor-profile col-lg-8 col-md-12 col-sm-12 col-12 row"
          >
            <div
              class="info col-lg-7 col-md-8 col-sm-12 col-12"
              style="min-width : 200px"
            >
              <h4 class="title">
                안녕하세요?<br />{{ pr.hospNm }} {{ pr.drNm }}입니다.
              </h4>
              <div class="history">
                <p v-html="computedLine(pr.drHist)"></p>
              </div>
              <div class="content">
                <p v-html="computedLine(pr.doctIntro)"></p>
              </div>
            </div>
            <div
              class="d-pircture profile col-lg-1 col-md-3 col-sm-12 col-12"
              v-if="
                GET_PROMOTION.drPicAttchId === '' ||
                  GET_PROMOTION.drPicAttchId === null
              "
            >
              <div class="put-profile noneHover" @click="attachmentFile(0)">
                <!--
              <img src="@/assets/images/attach_circle.svg" />
              <p class="hint">Doctor</p>
              --></div>
            </div>
            <div
              class="d-pircture profile col-lg-1 col-md-3 col-sm-12 col-12"
              v-else
            >
              <div class="img-wrap">
                <img :src="pr.drPicAttchId" />
              </div>
            </div>
          </div>
        </div>
        <div class="pr-block hospital">
          <div class="top-cliner"></div>
          <h2 class="title">{{ pr.hospNm }}</h2>
          <div class="h-info">
            <div class="first">
              <p v-html="computedLine(pr.hospIntro)"></p>
            </div>
            <div class="second">
              <p v-html="computedLine(pr.conConts)"></p>
            </div>
          </div>
          <div class="bottom-cliner"></div>
        </div>
        <div class="pr-block row" style="width : 100%">
          <div class="pre-content col-lg-3 col-sm-12 col-12">
            <h3 class="title" style="padding : 0px">
              {{ pr.hospNm }}
            </h3>
          </div>
          <div class="block-content col-lg-7 col-sm-12 col-12">
            <div class="article">
              <p class="type">주소</p>
              <div class="a-content">
                <p>{{ pr.addr }} {{ pr.addrDtl }}</p>
              </div>
            </div>
            <div class="article">
              <p class="type">전화, 팩스</p>
              <div class="a-content">
                <div class="phone-wrap">
                  <p v-if="pr.hospTel !== null && pr.hospTel !== ''">
                    전화: {{ pr.hospTel }}
                  </p>
                  <p v-if="pr.hospFaxNo !== null && pr.hospFaxNo !== ''">
                    팩스: {{ pr.hospFaxNo }}
                  </p>
                </div>
              </div>
            </div>
            <div class="article">
              <p class="type">웹사이트</p>
              <div class="input-grid a-content">
                <div class="url-list">
                  <p v-if="pr.homepage !== null && pr.homepage !== ''">
                    홈페이지<a :href="'http://' + pr.homepage">{{
                      pr.homepage
                    }}</a>
                  </p>
                  <p v-if="pr.rsvUrl !== null && pr.rsvUrl !== ''">
                    예약페이지<a :href="'http://' + pr.rsvUrl">{{
                      pr.rsvUrl
                    }}</a>
                  </p>
                  <p v-for="(etcP, index) in pr.etcPage" :key="index + 'e'">
                    {{ etcP.type
                    }}<a
                      :href="'http://' + etcP.value"
                      v-if="pr.etcPage[index].value"
                      >{{ etcP.value }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pr-block row">
          <div class="pre-content col-lg-3 col-sm-12 col-12">
            <h3 class="title">진료시간</h3>
          </div>
          <div class="block-content dotted col-lg-3 col-sm-12 col-12">
            <div class="article">
              <div class="a-content treatmentTime">
                <p v-html="computedLine(pr.etcConts)"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="pr-block row">
          <div class="pre-content col-lg-3 col-sm-12 col-12">
            <h3 class="title">병원사진</h3>
          </div>

          <div
            class="block-content hsPicrue col-lg-3 col-sm-12 col-12"
            v-if="
              GET_PROMOTION.picAttchId === '' ||
                GET_PROMOTION.picAttchId === null
            "
          >
            <div class="put-whole-img noneHover">
              <!--
            <img src="@/assets/images/attach_circle.svg" />
            <p class="hint">Hospital picture</p>
            --></div>
          </div>
          <div class="block-content hsPicrue" v-else>
            <div class="picture-wrap">
              <img :src="pr.picAttchId" />
            </div>
          </div>
        </div>
        <div class="pr-block last row">
          <div class="pre-content col-lg-3 col-sm-12 col-12">
            <h3 class="title">오시는 길</h3>
          </div>
          <div
            class="block-content hsPicrue col-lg-3 col-sm-12 col-12"
            v-if="
              GET_PROMOTION.mapAttchId === '' ||
                GET_PROMOTION.mapAttchId === null
            "
          >
            <div class="put-whole-img noneHover">
              <!--
            <img src="@/assets/images/attach_circle.svg" />
            <p class="hint">Map picture</p>
            --></div>
          </div>
          <div class="block-content hsMap" v-else>
            <div class="picture-wrap">
              <img :src="pr.mapAttchId" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="editor-box container on-special"
        v-if="this.pr.docAddvTitle !== null"
      >
        <div class="e-article">
          <h3 class="title" v-text="this.pr.docAddvTitle"></h3>
        </div>
        <div class="e-article">
          <div class="editor-wrap">
            <p class="content" v-html="this.pr.docAddvConts"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="pr-wrap default onView" v-if="view.pageOrder === 2">
      <div class="ab-btb-wrap" v-if="loadBtn === 0">
        <div class="prev-btn a-btn-item" @click="closeTab()">
          <div class="img-wrap">
            <img class="close" src="@/assets/images/menuIcon/cancel.svg" />
          </div>
          <p>닫기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 1">
        <div class="prev-btn a-btn-item" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/prev-btn.svg" />
          </div>
          <p>뒤로가기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 2">
        <div class="a-btn-item home-btn" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/btn_home.png" />
          </div>
          <p>홈페이지</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="pr-context">
        <div class="ab-top-bg">
          <img src="@/assets/images/pr-top-bg.png" />
        </div>
        <div class="ab-bottom-bg">
          <img src="@/assets/images/pr-bottom-bg.png" />
        </div>
        <div class="front">
          <div class="container">
            <div class="title hospital-logo row">
              <div
                class="put-logo noneHover col-md-4 col-sm-12"
                v-if="
                  GET_PROMOTION.logoAttchId === '' ||
                    GET_PROMOTION.logoAttchId === null
                "
              ></div>
              <div class="img-wrap col-md-4 col-sm-12" v-else>
                <img :src="pr.logoAttchId" style="min-width: 256px" />
              </div>

              <h2 class="col-md-5" style="min-width: 320px">{{ pr.hospNm }}</h2>
            </div>
            <div class="sub-title">
              <h3 v-html="computedLine(pr.hospIntro)"></h3>
            </div>
            <div class="vision">
              <p v-html="computedLine(pr.conConts)"></p>
            </div>
          </div>
        </div>
        <div class="back">
          <div class="container-wrap">
            <div class="container">
              <div class="profile row">
                <div
                  class="
                    put-profile
                    noneHover
                    col-lg-3 col-md-12 col-sm-12 col-12
                  "
                  v-if="
                    GET_PROMOTION.drPicAttchId === '' ||
                      GET_PROMOTION.drPicAttchId === null
                  "
                ></div>

                <div
                  class="img-wrap col-lg-3 col-md-12 col-sm-12 col-12"
                  v-else
                >
                  <img :src="pr.drPicAttchId" style="min-width: 209px" />
                </div>
                <div class="p-context col-lg-8 col-md-12 col-sm-12 col-12">
                  <div class="header">
                    <h4 class="title">
                      안녕하세요?<br />{{ pr.hospNm }} {{ pr.drNm }}입니다.
                    </h4>
                    <h6
                      class="history"
                      style="min-width: 250px"
                      v-html="computedLine(pr.drHist)"
                    ></h6>
                  </div>
                  <div class="content">
                    <p
                      style="min-width: 250px"
                      v-html="computedLine(pr.doctIntro)"
                    >
                      {{ pr.doctIntro }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="hospital">
                <h4 class="title">{{ pr.hospNm }}</h4>
                <div class="info row">
                  <div
                    class="article-wrap wide col-lg-7"
                    style="min-width: 320px"
                  >
                    <div class="article">
                      <h5 class="title">주소</h5>
                      <p>{{ pr.addr }} {{ pr.addrDtl }}</p>
                    </div>
                    <div class="article">
                      <h5 class="title">전화,팩스</h5>
                      <p
                        v-if="
                          (pr.hospTel !== null) &
                            (pr.hospFaxno !== null) &
                            (pr.hospTel !== '') &
                            (pr.hospFaxno !== '')
                        "
                      >
                        전화: {{ pr.hospTel }} 팩스: {{ pr.hospFaxNo }}
                      </p>
                      <p
                        v-if="
                          (pr.hospTel !== null) &
                            (pr.hospFaxno === null) &
                            (pr.hospTel !== '') &
                            (pr.hospFaxno === '')
                        "
                      >
                        전화: {{ pr.hospTel }}
                      </p>
                    </div>
                    <div class="article sample-view">
                      <h5 class="title">웹사이트</h5>
                      <div class="input-grid">
                        <p
                          class="type-name"
                          v-if="pr.homepage !== null && pr.homepage !== ''"
                        >
                          홈페이지
                          <a :href="'http://' + pr.homepage">{{
                            pr.homepage
                          }}</a>
                        </p>
                        <p
                          class="type-name"
                          v-if="pr.rsvUrl !== null && pr.rsvUrl !== ''"
                        >
                          예약페이지
                          <a :href="'http://' + pr.rsvUrl">{{ pr.rsvUrl }}</a>
                        </p>
                        <p
                          class="type-name"
                          v-for="(etcP, index) in pr.etcPage"
                          :key="index + 'e'"
                        >
                          {{ etcP.type }}
                          <a
                            :href="'http://' + etcP.value"
                            v-if="pr.etcPage[index].value"
                            >{{ etcP.value }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="article-wrap default">
                    <div class="article">
                      <h5 class="title">진료시간</h5>
                      <p v-html="computedLine(pr.etcConts)"></p>
                    </div>
                  </div>

                  <div class="article-wrap whole separated">
                    <div class="article hsPicrue">
                      <h5 class="title">병원사진</h5>
                      <div
                        class="put-whole-img noneHover"
                        v-if="
                          GET_PROMOTION.picAttchId === '' ||
                            GET_PROMOTION.picAttchId === null
                        "
                      ></div>

                      <div class="picture-wrap" v-else>
                        <img :src="pr.picAttchId" />
                      </div>
                    </div>
                  </div>

                  <div class="article-wrap whole">
                    <div class="article">
                      <h5 class="title">오시는길</h5>
                      <div
                        class="put-whole-img noneHover"
                        v-if="
                          GET_PROMOTION.mapAttchId === '' ||
                            GET_PROMOTION.mapAttchId === null
                        "
                      ></div>

                      <div class="map-wrap" v-else>
                        <img :src="pr.mapAttchId" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="editor-box container" v-if="this.pr.docAddvTitle !== null">
        <div class="e-article">
          <h3 class="title" v-text="this.pr.docAddvTitle"></h3>
        </div>
        <div class="e-article">
          <div class="editor-wrap">
            <p class="content" v-html="this.pr.docAddvConts"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="pr-wrap default onView wholeImg" v-if="view.pageOrder === 3">
      <div class="ab-btb-wrap" v-if="loadBtn === 0">
        <div class="prev-btn a-btn-item" @click="closeTab()">
          <div class="img-wrap">
            <img class="close" src="@/assets/images/menuIcon/cancel.svg" />
          </div>
          <p>닫기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 1">
        <div class="prev-btn a-btn-item" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/prev-btn.svg" />
          </div>
          <p>뒤로가기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="ab-btb-wrap" v-if="loadBtn === 2">
        <div class="a-btn-item home-btn" @click="goBack()">
          <div class="img-wrap">
            <img src="@/assets/images/btn_home.png" />
          </div>
          <p>홈페이지</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="hyperPdf()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="pr-context notification">
        <div class="item">
          <div class="img-wrap">
            <img :src="pr.prAttchId" />
          </div>
        </div>
      </div>
    </div>
    <div class="pr-wrap default onView wholeImg" v-if="view.pageOrder === 4">
      <div class="ab-btb-wrap">
        <div class="prev-btn a-btn-item" @click="closeTab()">
          <div class="img-wrap">
            <img class="close" src="@/assets/images/menuIcon/cancel.svg" />
          </div>
          <p>닫기</p>
        </div>
        <div class="a-btn-item refer-btn" @click="request()" v-if="isLogin">
          <div class="img-wrap">
            <img src="@/assets/images/refer_btn.png" />
          </div>
          <p>의뢰서 작성</p>
        </div>
        <div class="pdf-btn a-btn-item" @click="popupDisabled()">
          <div class="img-wrap">
            <img src="@/assets/images/document-btn4.svg" />
          </div>
          <p>의사소개 출력</p>
        </div>
      </div>
      <div class="pr-context notification">
        <div class="item prepare">
          <div class="content-empty">
            <h4 class="title">현재 페이지 제작중 입니다.</h4>
            <h4 class="title">
              병원 홈페이지 :
              <a :href="'http://' + pr.homepage">https://{{ pr.homepage }}</a>
            </h4>
            <h4 class="title">
              병원 전화번호 :
              <a>{{ pr.hospTel }}</a>
            </h4>

            <div class="img-wrap">
              <img src="@/assets/images/prepare.svg" />
            </div>
            <div class="circle-wrap">
              <div class="circle first"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  components: {
    DefaultPopup,
    AppHeader,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      loadBtn: 0,
      popupSet: {},
      pr: {
        logoAttchId: null,
        hospNm: null,
        hospIntro: null,
        conConts: null,
        drPicAttchId: null,
        drNm: null,
        drHist: null,
        doctIntro: null,
        addrDtl: null,
        hospTel: null,
        hospFaxNo: null,
        etcConts: null,
        mapAttchId: null,
        picAttchId: null,
        prAttchId: null,
        rsvUrl: null,
        homepage: null,
        drId: null,
        docAddvTitle: null,
        docAddvConts: null,
        etcPage: [
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
        ],
      },
      view: {
        pageOrder: null || 0,
      },
      isLogin: false,
    };
  },
  created() {
    /*if (localStorage.getItem("token") === null) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }*/
    this.getBasicData();
    this.getPromotionData();
    this.decideBtn();
  },
  mounted() {
    //console.log("===============");
    //console.log(this.$route.name);
  },
  computed: {
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("util", ["BRING_PDF_PR", "BRING_PDF_URL_PR"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("request", ["SET_REPLY_STATUS"]),
    popupDisabled() {
      this.SET_POPUP(true);
      this.popupSet.title = "의사소개서 작성이 완료되지 않았습니다.";
      this.popupSet.content =
        "현재 의사소개서 작성이 완료되지않아 출력이 불가 합니다.";
      this.popupSet.popType = "custom";
      this.popupSet.cancelBtnText = undefined;
      this.popupSet.confirmBtnText = "확인";
    },
    computedLine(text) {
      if (text !== null) {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      }
    },
    decideBtn() {
      if (document.referrer) {
        //히스토리 없음 = 새창
        this.loadBtn = 0;
        // console.log("히스토리1");
      } else {
        // console.log("히스토리4");
        if (localStorage.getItem("drId") !== null) {
          // console.log("히스토리2");
          this.loadBtn = 1;
        } else {
          // console.log("히스토리3");
          this.loadBtn = 2;
        }
      }
    },
    checkCurrentStatus() {
      if (
        this.GET_PROMOTION.prAttchId !== null &&
        this.GET_PROMOTION.prAttchId !== ""
      ) {
        // console.log("prAttachment catch");
        return false;
      }
      if (
        this.GET_PROMOTION.homepage !== null &&
        this.GET_PROMOTION.homepage !== ""
      ) {
        // console.log("홈페이지에서 걸림");
        return false;
      }
      if (this.GET_PROMOTION.url1 !== null && this.GET_PROMOTION.url1 !== "") {
        // console.log("Url1 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.urlNm1 !== null &&
        this.GET_PROMOTION.urlNm1 !== ""
      ) {
        // console.log("Url1 네임");
        return false;
      }
      if (this.GET_PROMOTION.url2 !== null && this.GET_PROMOTION.url2 !== "") {
        // console.log("Url2 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.urlNm2 !== null &&
        this.GET_PROMOTION.urlNm2 !== ""
      ) {
        // console.log("Url2 네임");
        return false;
      }
      if (this.GET_PROMOTION.url3 !== null && this.GET_PROMOTION.url3 !== "") {
        // console.log("Url3 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.urlNm3 !== null &&
        this.GET_PROMOTION.urlNm3 !== ""
      ) {
        // console.log("Url3 네임");
        return false;
      }
      if (
        this.GET_PROMOTION.logoAttchId !== null &&
        this.GET_PROMOTION.logoAttchId !== ""
      ) {
        // console.log("로고 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.picAttchId !== null &&
        this.GET_PROMOTION.picAttchId !== ""
      ) {
        // console.log("병원사진 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.drPicAttchId !== null &&
        this.GET_PROMOTION.drPicAttchId !== ""
      ) {
        // console.log("의사사진 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.hospIntro !== null &&
        this.GET_PROMOTION.hospIntro !== ""
      ) {
        // console.log("병원소개 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.doctIntro !== null &&
        this.GET_PROMOTION.doctIntro !== ""
      ) {
        // console.log("의사소개 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.drHist !== null &&
        this.GET_PROMOTION.drHist !== ""
      ) {
        // console.log("의사연혁 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.conConts !== null &&
        this.GET_PROMOTION.conConts !== ""
      ) {
        // console.log("큰 통");
        return false;
      }
      if (
        this.GET_PROMOTION.etcConts !== null &&
        this.GET_PROMOTION.etcConts !== ""
      ) {
        // console.log("기타 통");
        return false;
      }
      return true;
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async callPdf() {
      if(this.pr.drId === null 
      || this.pr.drId === undefined)
      this.pr.drId = "";

      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

      if(!drIdBoo.test(this.pr.drId)){
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "서버에서 수신하지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }
      try {
        await this.BRING_PDF_URL_PR(this.pr.drId).then(() => {
          // console.log("댐", this.GET_PDF_URL);
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async fileCall() {
      try {
        await this.BRING_PDF_PR(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          // console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          // console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    closeTab() {
      // console.log("clo");
      window.open("", "_self").close();
    },
    goBack() {
      if (localStorage.getItem("drId") !== null) {
        // console.log(window.history.back());
        window.history.back();
        window.history.go(-2);
      } else {
        this.$router.push("/").catch(()=>{});
      }
    },
    goBackToHome() {
      this.$router.push("/").catch(()=>{});
    },
    phoneFomatter(number) {
      // console.log("정보", number);
      // console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        // console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    request() {
      // console.log("로그인 유무 검증", localStorage.getItem("token"));
      /*if (localStorage.getItem("token") !== null) {
        console.log(this.GET_BASIC);
        console.log(this.GET_PROMOTION);
        const doctorInfo = {
          drId: this.GET_BASIC.drId,
          receive: this.GET_BASIC.hospNm,
          doctor: this.GET_BASIC.drNm,
          referId: null,
          proObj: this.GET_PROMOTION.proObj,//////////////////////////////////
        };
        this.SET_REPLY_STATUS(doctorInfo);
        this.$router.push("/v/request/requestDocument");
      } else {
        this.$router.push("/v/request/requestDocument");
        //this.$refs.appheader.callLoginPop();
      }*/
      const doctorInfo = {
        drId: this.GET_BASIC.drId,
        receive: this.GET_BASIC.hospNm,
        doctor: this.GET_BASIC.drNm,
        referId: null,
        proObj: this.GET_PROMOTION.proObj, //////////////////////////////////
      };
      this.SET_REPLY_STATUS(doctorInfo);
      this.$router.push("/v/request/requestDocument").catch(()=>{});
    },
    async getBasicData() {
      if(this.$route.params.code === null 
      || this.$route.params.code === undefined)
      this.$route.params.code = "";

      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

      if(!drIdBoo.test(this.$route.params.code)){
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "서버에서 수신하지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      try {
        await this.FETCH_BASIC_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            // console.log("찾기 회원정보", this.GET_BASIC);
            if (this.GET_BASIC.hospNm !== null) {
              this.pr.hospNm = this.GET_BASIC.hospNm;
            }
            if (this.GET_BASIC.drNm !== null) {
              this.pr.drNm = this.GET_BASIC.drNm;
            }
            if (this.GET_BASIC.drId !== null) {
              this.pr.drId = this.GET_BASIC.drId;
            }
            if (this.GET_BASIC.addr !== null) {
              this.pr.addr = this.GET_BASIC.addr;
            }
            if (this.GET_BASIC.addrDtl !== null) {
              this.pr.addrDtl = this.GET_BASIC.addrDtl;
            }
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getPromotionData() {
      /**
       *  삭제,변경 => 1
       *  유지,입력 => 0
       *
       */
      if(this.$route.params.code === null 
      || this.$route.params.code === undefined)
      this.$route.params.code = "";

      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

      if(!drIdBoo.test(this.$route.params.code)){
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "서버에서 수신하지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }
      try {
        await this.FETCH_PROMOTION_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            // console.log("찾기 조회정보", this.GET_PROMOTION);
            if (this.GET_PROMOTION.logoAttchId !== null) {
              this.pr.logoAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
            }
            if (this.GET_PROMOTION.hospIntro !== null) {
              this.pr.hospIntro = this.GET_PROMOTION.hospIntro;
            }
            if (this.GET_PROMOTION.conConts !== null) {
              this.pr.conConts = this.GET_PROMOTION.conConts;
            }
            if (this.GET_PROMOTION.drPicAttchId !== null) {
              this.pr.drPicAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
            }
            if (this.GET_PROMOTION.drHist !== null) {
              this.pr.drHist = this.GET_PROMOTION.drHist;
            }
            if (this.GET_PROMOTION.doctIntro !== null) {
              this.pr.doctIntro = this.GET_PROMOTION.doctIntro;
            }
            if (this.GET_PROMOTION.hospTel !== null) {
              this.pr.hospTel = this.phoneFomatter(this.GET_PROMOTION.hospTel);
            }
            if (this.GET_PROMOTION.hospFaxNo !== null) {
              this.pr.hospFaxNo = this.phoneFomatter(
                this.GET_PROMOTION.hospFaxNo
              );
            }
            if (this.GET_PROMOTION.etcConts !== null) {
              this.pr.etcConts = this.GET_PROMOTION.etcConts;
            }
            if (this.GET_PROMOTION.mapAttchId !== null) {
              this.pr.mapAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
            }
            if (this.GET_PROMOTION.picAttchId !== null) {
              this.pr.picAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
            }
            if (this.GET_PROMOTION.prAttchId !== null) {
              this.pr.prAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
            }
            if (this.GET_PROMOTION.rsvUrl !== null) {
              this.pr.rsvUrl = this.GET_PROMOTION.rsvUrl;
            }
            if (this.GET_PROMOTION.homepage !== null) {
              this.pr.homepage = this.GET_PROMOTION.homepage;
            }
            if (
              this.GET_PROMOTION.url1 !== null &&
              this.GET_PROMOTION.url1 !== ""
            ) {
              this.pr.etcPage[0].value = this.GET_PROMOTION.url1;
            }
            if (
              this.GET_PROMOTION.urlNm1 !== null &&
              this.GET_PROMOTION.urlNm1 !== "" &&
              this.GET_PROMOTION.urlNm1 !== "선택안함"
            ) {
              this.pr.etcPage[0].type = this.GET_PROMOTION.urlNm1;
            }
            if (
              this.GET_PROMOTION.url2 !== null &&
              this.GET_PROMOTION.url2 !== ""
            ) {
              this.pr.etcPage[1].value = this.GET_PROMOTION.url2;
            }
            if (
              this.GET_PROMOTION.urlNm2 !== null &&
              this.GET_PROMOTION.urlNm2 !== "" &&
              this.GET_PROMOTION.urlNm2 !== "선택안함"
            ) {
              this.pr.etcPage[1].type = this.GET_PROMOTION.urlNm2;
            }
            if (
              this.GET_PROMOTION.url3 !== null &&
              this.GET_PROMOTION.url3 !== ""
            ) {
              this.pr.etcPage[2].value = this.GET_PROMOTION.url3;
            }
            if (
              this.GET_PROMOTION.urlNm3 !== null &&
              this.GET_PROMOTION.urlNm3 !== "" &&
              this.GET_PROMOTION.urlNm3 !== "선택안함"
            ) {
              this.pr.etcPage[2].type = this.GET_PROMOTION.urlNm3;
            }
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.pr.docAddvTitle = this.GET_PROMOTION.docAddvTitle;
            }
            if (
              this.GET_PROMOTION.docAddvConts !== null &&
              this.GET_PROMOTION.docAddvConts !== ""
            ) {
              this.pr.docAddvConts = this.GET_PROMOTION.docAddvConts;
            }
            if (this.GET_PROMOTION.hospIntroType !== null) {
              this.view.pageOrder = Number(this.GET_PROMOTION.hospIntroType);
            }
            /** 리스트의 정보기입 
            if (Object.keys(this.GET_LIST_DOC_INFO).length !== 0) {
              console.log("가져온 닥터리스트의 정보");
              this.getDocInfo.prevUrl = this.GET_LIST_DOC_INFO.prevUrl;
              if (this.GET_LIST_DOC_INFO.proObj !== "일반의") {
                this.getDocInfo.proObj = this.GET_LIST_DOC_INFO.proObj;
              }
            }
            */
            if (this.checkCurrentStatus()) {
              // console.log("아무런정보도 없는 초기회원 진입");
              this.view.pageOrder = 4;
            }
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/pr";
#app .app-context.util-page .app-header-wrap {
  height: auto;
}
.r-notice {
  position: relative;
  margin: 30px auto 15px;
  max-width: 948px;
  height: 62px;

  &:after {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    background: #146f83;
    top: calc(50% - 12px);
    transform: translateY(-50%);
    z-index: 1;
  }

  .notice {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 0 20px;
    background: #fff;

    p {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: normal;
      text-align: center;
      color: #474747;

      b {
        font-weight: bold;
        color: #146f83;
        margin-right: 3px;
      }
    }
  }
}

.write-context {
  max-width: 1140px;
  margin: 65px auto;

  .document {
    .j-article {
      .title-block {
        width: 260px;
      }

      .attachment-wrap,
      .input-wrap {
        width: calc(100% - 290px);
      }
    }
  }
}

.pr-button-wrap {
  position: relative;
  max-width: 948px;
  margin: 0 auto;

  button {
    &:hover {
      background: #146f83;
      box-shadow: none;
    }

    &.doReq {
    }

    &.pdf {
      margin: 10px 0 0 0;
    }

    border: 0;
    width: 100%;
    height: 64px;
    color: #fff;
    font-family: "SUIT";
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 64px;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #d0d0d0;
    overflow: hidden;
  }
}

.pr-wrap {
  position: relative;
  padding: 0 0 180px 0;
  &.default
    .pr-context
    .back
    .container
    .hospital
    .info
    .article-wrap
    .article.sample-view
    .input-grid
    p.type-name {
    width: 100%;
  }
  .ab-btb-wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    .a-btn-item {
      min-width: 110px;
      &.pdf-btn {
        margin: 0 0 0 10px;
        .img-wrap {
          background-color: #313c49;
        }
      }
      &.refer-btn {
        margin: 0 0 0 10px;
        .img-wrap {
          background-color: #2f4e74;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
      &.home-btn {
        margin: 0 0 0 10px;
        .img-wrap {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
      .img-wrap {
        width: 66px;
        height: 66px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 0 auto 10px auto;
        img.close {
          width: 40px;
          height: 40px;
        }
        img {
        }
      }
      p {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 27px;
        letter-spacing: normal;
        text-align: center;
        color: #313c49;
      }
    }
  }
}
</style>
<style lang="scss">
.raw-html-embed {
  margin: 15px 0;
}

figure > img {
  max-width: 100%;
}
.pr-wrap.default
  .pr-context
  .back
  .container
  .hospital
  .info
  .article-wrap
  .article.hsPicrue
  .picture-wrap
  img {
  max-height: 400px !important;
  min-height: 207px !important;
  min-width: 250px !important;
}

.pr-wrap.special .pr-block .block-content.hsPicrue .picture-wrap,
.pr-wrap.special .pr-block .block-content.hsMap .picture-wrap {
  width: 100% !important;
}



@media screen and (max-width: 991px) {
  .pr-wrap.special .pr-block .pre-content h3.title {
    text-align: left !important;
  }
  .pr-block.align-center.first {
    .block-content.doctor-profile {
      margin: 10px !important;
      p {
        margin: 0px 10px 0 0 !important;
      }
    }
    .pre-content1 {
      margin: 0px !important;
    }
  }

  .pr-wrap.special .pr-block .block-content,
  .pr-wrap.special .pr-block .pre-content {
    margin: 0 50px 0px 50px !important;
    width: 100% !important;
  }

  .pr-wrap.special
    .pr-block
    .block-content
    .article
    .a-content.input-grid
    .url-list
    p {
    margin: 0px 30px 0 0 !important;
  }
  .pr-wrap.special .pr-block .pre-content h3.title {
    padding: 0 !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 767px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 750px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pr-wrap.special
    .pr-block
    .block-content.doctor-profile
    .d-pircture
    .img-wrap {
    width: 100% !important;
    img {
      margin: auto !important;
    }
  }
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      width: 100% !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 604px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 580px !important;
      }
    }
  }
}
@media screen and (max-width: 604px) {
  .pr-wrap.default
    .pr-context
    .back
    .container
    .hospital
    .info
    .article-wrap.default {
    margin: 50px 0 0 0px !important;
    width: 100% !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 576px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 540px !important;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .pr-wrap.default .pr-context .front .container div.title .img-wrap {
    flex: 0 0 100% !important;
    img {
    }
  }

  .pr-wrap.special .pr-block .block-content .article p.type {
    margin: 0px !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 360px !important;
    min-height: 450px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 320px !important;
      }
    }
    .item.prepare {
      .content-empty {
        h4.title {
          font-size: 18px !important;
          position: relative;
          top: -30px !important;
          margin-bottom: -10px;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
      }
    }
    .circle-wrap {
      width: 200px !important;
    }
  }
}
@media screen and (max-width: 360px) {
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      min-width: 280px !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 300px !important;
    min-height: 400px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 300px !important;
      }
    }
    .item.prepare {
      .content-empty {
        h4.title {
          font-size: 18px !important;
          position: relative;
          top: -20px !important;
          margin-bottom: -10px;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
      }
    }
    .circle-wrap {
      width: 200px !important;
    }
  }
}
</style>
